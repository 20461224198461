@import './variables.scss';

.home-logo {
    height: 250px;
    width: 250px;
    background-color: transparent;
    margin: 50px 0px;
}

.social-icon-bar {
    padding: 5px 20px 0px;
    background-color: rgba($color: #FFF, $alpha: 1);
    // border: 2px solid #373635;
    border-radius: 10px;
    font-size: 26px;
    color: #373635;
    // box-shadow: 5px 5px 5px rgba($color: #000000, $alpha: 0.4);
}

.home-banner {
    background-size: auto 100%;
    background-position: center center;
    // background-image: url('../assets/imgs/banners/01.jpg');
}

.full-banner {
    background-size: auto 100%;
    background-position: center center;
    // background-image: url('../assets/imgs/banners/02.jpg');

    &.surf-ballito {
        background-image: url('../assets/imgs/banners/surf_ballito.jpg');        
    }
}

.banner {
    &.banner-home {
    }
    &.banner-01 {
    }
    &.banner-02 {
    }
}

.tile {
    &.tile-image {
        .tile-image-block {
        }
    }
 }

.loader-ball {
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-size: 30px auto;
    min-height: 100px;
    min-width: 100px;

    &.loader-black {
        background-image: url('../assets/imgs//big_circle_ball_white.gif');
    }
    
    &.loader-white {
        background-image: url('../assets/imgs//big_circle_ball_black.gif');
    }
}

.ion-button-style {
    background-color: $secondary;
    color: $primary;
    border-radius: 10px;
}

.highlight {
    background-color: $secondary;
    --background: $secondary;
    color: $primary;
}
.total-row {
    border-top: 2px solid #000;
    border-bottom: 2px double #000;
}

.zapper-logo-text {
    height:20px;
}

.zapper-qr {
    height: auto;
    
    img, svg {
        width: auto;
        max-width: 250px;
        height: auto;
        max-height: 250px;
        padding: 1em;
    }
}

.sml-text {
    font-size: 12px;
}

@import './variables.scss';

.add-shadow {
    text-shadow: 3px 3px 3px #000;
}

.add-box-shadow {
    filter: drop-shadow(0px 10px 3px rgba(#000, 0.3));
}

.mid-line {
    display: inline-block;
    width: 20vw;
    border-top: 1px solid;

    &.dark-line {
        border-top-color: $dark;
    }

    &.light-line {
        border-top-color: $secondary;
    }
}

.page-banner {
    &.banner-home {
        // background-image: url('../assets/imgs/banners/08.jpg');

        color: $secondary;
        text-shadow: 2px 2px 2px rgba($color: $primary, $alpha: 0.5);
    }
    &.banner-second {
        // background-image: url('../assets/imgs/banners/07.jpg');

        color: $secondary;
        text-shadow: 2px 2px 2px rgba($color: $primary, $alpha: 0.5);
    }
}

.main-banner {
    &.lessons {
        background-image: url('../assets/imgs/banners/ballitosurfschool.com-surf-lessons-banner.jpg');
    }
    &.merchandise {
        background-image: url('../assets/imgs/banners/ballitosurfschool.com-surf-merchandise-banner.jpg');
    }
}

.text-sub-heading {
	font-size: 35px;
	line-height: 35px;
}

.button-outline {
    --border-color: rgba($color: $primary, $alpha: 1);
    color: $primary!important;
}

ion-tabs {
    ion-tab-bar {
        height: 50px;
        padding: 10px 0px;
        // border-bottom: 4px solid rgba($color: $primary, $alpha: 1);
    
        ion-tab-button {
            font-weight: bold;
            font-size: 16px;
            background-position: center center;
            background-size:  100% auto;

            &.tab-selected {
                font-weight: bold;
            }

            .tabs-logo {
                max-height: 35px;
                max-width: 100%;
                height: auto;
                width: auto;
            }
        }
    }
}

.mike-content {
    background-image: url('/assets/imgs/sections/mike.jpg');
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    // background-attachment: fixed;
    
    h1 {
        color: #000;
        font-weight: bold;
        text-shadow: 5px 2px 2px rgba($color: #000000, $alpha: 0.5);
    }

    .mike-stats {
        position: absolute;
        top: 50%;
        right: 50%;
        max-width: 500px;
        transform: translateY(-50%);
    }
}

.hikes-content {
    background-image: url('/assets/imgs/sections/hikes.jpg');
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.banner-style {
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    h1 {
        color: #FFF;

        &.smaller {
            font-size: 40px;
        }
    }
}

.gatberg-banner {
    background-image: url('/assets/imgs/sections/hikes.jpg');
}

.tugela-falls-banner {
    background-image: url('/assets/imgs/sections/tugela-falls.jpg');
}

.mnweni-banner {
    background-image: url('/assets/imgs/sections/mnweni.jpg');
}

.marble-baths-banner {
    background-image: url('/assets/imgs/sections/marble-baths.jpg');
}

.footer-content {
    position: relative;
    background-size: 100% auto;
    background-color: $primary;
    color: $dark;
    padding: 50px;
    
    .footer-gradient {
        position: absolute;
        z-index: 1;
        top: 0px; left:0px;
        height: 200px;
        width: 100%;

        background-image: linear-gradient(180deg, #FFF, rgba(0,0,0,0));
    }

    h4 {
        position: relative;
        color: $dark;
        z-index: 100;

        a {
            text-decoration: none;
        }

        span {
            display: inline-block;
            border-right: 1px solid $dark;
            padding: 10px;

            &:last-child {
                border: none;
            }
        }
    }
}

.app-modal-style {
    border: 5px solid $primary;
}

.cookie-message {
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -150px;
    width: 300px;
    z-index: 999;
    border-radius: 10px;
    border: 2px solid $primary;
    background-color: $secondary;
    color: $primary;
}

.bt-5 {
    border-top: 5px solid #000;
}

.vert-top {
    vertical-align: top;
}

// .button-outline {
//     --border-color: rgba($color: $secondary, $alpha: 0.6);
//     color: $secondary!important;
// }

ion-card {
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
    color: #000;

    &.tile-image {
        .tile-image-text {
            background-color: $secondary;
            color: $primary;
        }
    }
}

.tile {
    &.tile-image {
        .tile-image-block {
            &.tile-image-accessories {
            }

            &.tile-image-boards {
            }

            &.tile-image-repairs {
            }
        }
    }
 }

//.banner {
    // &.banner-01 {
    //     background-image: url('../assets/imgs/banners/banner1.png');
    // }
    // &.banner-02 {
    //     background-image: url('../assets/imgs/banners/banner2.png');
    // }
    // &.banner-03 {
    //     background-image: url('../assets/imgs/banners/banner3.png');
    // }
    // &.banner-04 {
    //     background-image: url('../assets/imgs/banners/banner4.png');
    // }
    // &.banner-05 {
    //     background-image: url('../assets/imgs/banners/banner5.png');
    // }
    // &.banner-06 {
    //     background-image: url('../assets/imgs/banners/banner6.png');
    // }
//}


.bg-trans {
    background-color: rgba($color: $secondary, $alpha: 1);
    color: $primary;

    a {
        color: $primary;
    }

    &.text-invert {
        color: #000;

        a {
            color: #000;
        }
    }

    &.super-trans {
        background-color: rgba($color: $secondary, $alpha: 0.1);
    }

    &.dark-trans {
        background-color: rgba($color: #000, $alpha: 0.6);
    }

    &.darker-trans {
        background-color: rgba($color: #000, $alpha: 0.8);
    }

    ion-chip {
        background-color: $primary;
    }
}

.bg-dark {
    background-color: #000;
}

.swiper-pagination-bullet {
    background-color: $primary!important;
    border: 1px solid $secondary;

    &.swiper-pagination-bullet-active {
        background-color: $secondary!important;
        border: 1px solid $primary;
    }
  }

.footer-chips {
    // border-top: 1px solid $secondary;
    padding: 5px 0px;
    // background-color: #000;

    ion-chip {
        background-color: $primary;
        font-size: 12px;

        &:hover {
            background-color: rgba($color: $secondary, $alpha: 0.6);
            color: $primary;
            transition: all 0.5s ease;
        }
    }
}

.footer-sa-surf-hub {
    color: #000;
    a {
        color: #000;
        text-decoration: none;
    }

    img {
        height: 50px;
    }
}
.footer-logo {
    // color: $secondary;
    font-size: 12px;

    img{ 
        height: 150px;
        width: auto;
    }
}

.payment-logos {
    img {
        height: 50px;
        width: auto;
    }
}

.dark-pop {
    background-color: #000;
    color: $primary;
}

.heading-font {
    // color: rgba($color: $secondary, $alpha: 0.6);
    // color: $primary;

    h1 {
        span {
            border-bottom: 3px dashed #000;
            padding: 2px 15px;
        }
    }

    // h1 {
    //     text-shadow: 2px 2px rgba($color: #000000, $alpha: 0.6);
    //     span {
    //         // border-bottom: 3px dashed $primary;
    //         padding: 2px 15px;
    //     }
    // }
}

/***** MENU ITEMS *****/
.price-badge {
    background-color: rgba($color: $primary, $alpha: 0.6);
    color: $secondary;
    font-family: 'LuloCleanOne';
}
/***** MENU ITEMS *****/


/*** CHIP MENU ***/

.chip-menu {
    color: $secondary;
}

/*** CHIP MENU ***/
@media only screen and (max-width: 768px) { 
    
    .mike-content, .footer-content {
        background-size: auto 100%;
    }

    .footer-content {
        h4 {
            span {
                display: block;
                border-right: none;
            }
        }
    }

    .banner-style {
        background-attachment: unset;
    }

    .banner {
        &.banner-02 {
            background-position: -250px center; 
        }
        &.banner-04 {
            background-position: -250px center;
        }
    }

    .sml-center {
        text-align: center!important;
    }
}