@import './variables.scss';

body {
    font-family: 'main-font';
}
:root {
    --ion-font-family: 'main-font';
}

.btn {
    border: 1px solid $primary;
}

.vert-bottom {
    vertical-align: bottom;
}
.bg-pri {
    background-color: $primary;
    color: $secondary;

    h1, a {
        color: $secondary;
    }
}

.bg-sec {
    background-color: $secondary;
    color: $primary;

    h1, a {
        color: $primary;
    }

    .btn {
        border: 1px solid $primary;
    }

    ion-chip {
        border: 1px solid $primary;
        color: $primary;
        background-color: $secondary;

        &.minimal {
            font-size: 11px;
        }

        &.selected {
            color: $secondary;
            background-color: $primary;
        }
    }
}

.bg-ter {
    background-color: $tertiary;
    color: $secondary;

    h1, a {
        color: $secondary;
    }
}

.bg-pri-7 {
    background-color: rgba($color: $primary, $alpha: 0.7);
    color: $secondary;
}

.txt-pri {
    color: $primary!important;
}
.txt-sec {
    color: $secondary!important;
}

.loading-screen {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;

    .loading-logo {
        height: 175px;
        width: auto;
    }

    z-index: 999;
}

.full-page {
    position: relative;
    width: 100vw;
    min-height: 95vh;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
}

.three-quart-page {
    position: relative;
    width: 100vw;
    min-height: 75vh;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
}

.half-page {
    position: relative;
    width: 100vw;
    min-height: 50vh;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
}

.fifth-page {
    position: relative;
    width: 100vw;
    height: 20vh;
    min-height: 400px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
}

.service-holder {
    position: relative;

    .service {
        position: relative;
        display: inline-block;
        width: 50%;
        height: 50vh;

        img {
            border: 2px solid $primary;
        }

        table {
            width: 100%;

            thead {
                background-color: $primary;
                color: $secondary;
            }

            td {
                width: 33%;
                text-align: center;
                vertical-align: top;

                border: 2px solid $primary;

                div {
                    padding: 5px;
                }

                &.small-text {
                    font-size: 14px;
                }
            }
        }
    }
}

.waiting-logo {
    max-height: 250px;
    width: auto;
}

.footer-logo {
    max-height: 120px;
    width: auto;
    padding: 10px;
    border-radius: 10px;
}

.fixed-bg {
    background-attachment: fixed;
}

.page-banner {
    position: relative;

    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
}

.main-banner {
    position: relative;
    padding: 90px 30px 120px 30px;
    height: 70vh;
    min-height: 500px;
    vertical-align: bottom;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/*** CONTAINERS ***/
.container-max {
	max-width: 800px;
}
.container-wider {
    position: relative;
	max-width: 1000px;
    height: 100%;
}
.main-header {
    // display: flex;
    // flex-basis: auto;
    // flex-grow: 0;
    // flex-shrink: 1;
    position: absolute;
    bottom: 0px;
    left: 0px;
}
/*** CONTAINERS ***/

.page-logo {
    position: relative;
    height: 60vh;
}

.page-menu {
    position: relative;

    .page-menu-item {
        position: relative;
        display: inline-block;

        a {
            display: block;
            text-decoration: none;
            width: 125px;
            // border: 1px solid #000;
            // border-radius: 10px;
            margin: 5px 5px;
        }
    }
}

.make-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 200;
}

/***** MENU ITEMS *****/
.heading-main  {
	font-size: 40px!important;
	line-height: 50px;
	letter-spacing: -2,7px;
}

.heading-product-name {
	font-size: 24px!important;
	line-height: 35px;
	font-weight: normal;
}

.product-display-holder {
    margin: 0px auto;
    max-width: 800px;

    .product-display {
        display: inline-block;
        width: 50%;
        padding: 50px;

        .product-display-inner {
            margin: 25px 0px;
        }
    }
}

.price-badge {
    display: inline-block;
    padding: 8px 15px;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    z-index: 99;

    &.badge-abs {
        position: absolute;
        top: 5px;
        left: 5px;
    }
}

.cross-out {
    text-decoration: line-through;
}
/***** MENU ITEMS *****/

/***** aligment *****/



.bottom-middle {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
}

.middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.middle-vert {
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
}

.fade-out {
    transform: translateX(50%);
    opacity: 0;

    transition: all 0.5s ease;
}
.fade-in {
    transform: translate(-50%, -50%);

    opacity: 1;

    transition: all 0.5s ease;
}

.top-right {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
}

.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
.text-justify {
    text-align: justify;
}

/***** border *****/

.no-border {
    box-shadow: none !important;
}

/***** width *****/

.m-auto {
    margin: 0px auto;
}
.max-width-card {
    width: 100%;
    max-width: 675px;
    display: block;
    margin: 0px auto;
}

.max-width-card-double {
    width: 1050px;
    display: block;
    margin: 0px auto;
}

/***** margining *****/
.m-0 {
    margin: 0px!important;
}
.m-1 {
    margin: 0.5em;
}

.m-2 {
    margin: 1em;
}

.m-3 {
    margin: 1.5em;
}

.m-4 {
    margin: 2em;
}

.m-5 {
    margin: 2.5em;
}

.mb-1 {
    margin-bottom: 0.5em;
}

.mb-2 {
    margin-bottom: 1em;
}

.mb-3 {
    margin-bottom: 1.5em;
}

.mb-4 {
    margin-bottom: 2em;
}

.mb-5 {
    margin-bottom: 2.5em;
}

.mt-1 {
    margin-top: 0.5em;
}

.mt-2 {
    margin-top: 1em;
}

.mt-3 {
    margin-top: 1.5em;
}

.mt-4 {
    margin-top: 2em;
}

.mt-5 {
    margin-top: 2.5em;
}

.mr-a {
    margin-right: auto;
}

.ml-a {
    margin-left: auto;
}

.ml-1 {
    margin-left: 0.5em;
}

.ml-2 {
    margin-left: 1em;
}

.ml-3 {
    margin-left: 1.5em;
}

.ml-4 {
    margin-left: 2em;
}

.ml-5 {
    margin-left: 2.5em;
}

.mr-1 {
    margin-right: 0.5em;
}

.mr-2 {
    margin-right: 1em;
}

.mr-3 {
    margin-right: 1.5em;
}

.mr-4 {
    margin-right: 2em;
}

.mr-5 {
    margin-right: 2.5em;
}

/***** padding *****/

.p-0 {
    padding: 0px!important;
}

.p-half {
    padding: 0.5em;
}

.p-1 {
    padding: 1em;
}

.p-2 {
    padding: 1.5em;
}

.p-3 {
    padding: 2em;
}

.p-4 {
    padding: 2.5em;
}

.p-5 {
    padding: 3em;
}

.pb-1 {
    padding-bottom: 0.5em;
}

.pb-2 {
    padding-bottom: 1em;
}

.pb-3 {
    padding-bottom: 1.5em;
}

.pb-4 {
    padding-bottom: 2em;
}

.pb-5 {
    padding-bottom: 2.75em;
}

.pt-1 {
    padding-top: 0.5em;
}

.pt-2 {
    padding-top: 1em;
}

.pt-3 {
    padding-top: 1.5em;
}

.pt-4 {
    padding-top: 2em;
}

.pt-5 {
    padding-top: 2.75em;
}

.pr-1 {
    padding-right: 1em;
}

.pr-2 {
    padding-right: 1.5em;
}

.pr-3 {
    padding-right: 2em;
}

.pr-4 {
    padding-right: 2.5em;
}

.pr-5 {
    padding-right: 3em;
}

.pl-1 {
    padding-left: 1em;
}

.pl-2 {
    padding-left: 1.5em;
}

.pl-3 {
    padding-left: 2em;
}

.pl-4 {
    padding-left: 2.5em;
}

.pl-5 {
    padding-left: 3em;
}

/*** WIDTH ***/
.w-100 {
    width: 100%!important;
}
.w-50 {
    width: 50%!important;
}
.w-25 {
    width: 25%!important;
}

/***** H1 *****/

h1, h2, h3, h4, h5, h6, div {
    &.smaller-font {
        font-size: 14px;
    }
    &.bigger-font {
        font-size: 25px;
    }
    &.med-font {
        font-size: 40px;
    }
    &.big-font {
        font-size: 60px;
    }
}

a {
    &.large-link {
        font-size: 24px;
    }
}

/***** SPAN *****/

span {
    &.tag-cloud {
        font-size: 12px;
        padding: 3px;
        display: inline-block;
    }
}

/***** SPAN *****/

// .schedule-tile-holder {
//     display: inline-block;

//     .schedule-tile {
//         display: table;
//         // border: 1px solid;
//         border-radius: 5px;
    
//         .shedule-tile-row {
//             display: table-row;
    
//             .shedule-tile-cell {
//                 display: table-cell;

//                 &:first-child {
//                     width: 100px;
//                 }
//             }
//         }
//     }
// }

// .schedule-tile {
//     border: 1px solid $primary;
// }


/***** chessboard *****/
.chessboard {
    position: relative;
    display: table;
    width: 100%;

    .chessboard-row {
        display: table-row;

        .chessboard-column {
            display: table-cell;
            position: relative;
            text-align: center;
            height: 350px;
            min-height: 350px;

            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% auto;

            .chessboard-text {
                position: absolute;
                bottom: 20px;
                left: 0px;
                width: 100%;
                font-size: 32px;
                // border: 1px solid #000;
                // background-color: rgba($color: #000000, $alpha: 0.7);
                // border-radius: 10px;
                // padding: 10px 25px;
                
                // width: 80%;
            }
        }
    }
}
/***** chessboard *****/

/***** img-row *****/
.img-row-holder {
    position: relative;
    display: table;
    width: 100%;

    .img-row {
        display: table-row;

        .img-row-column {
            // display: table-cell;
            position: relative;
            text-align: center;
            vertical-align: top;
            height: auto;

            &.wid-5 {
                width: 20%;
            }
            &.wid-4 {
                width: 25%;
            }
            &.wid-3 {
                width: 33%;
            }
            &.wid-2 {
                width: 50%;
            }
            
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
/***** img-row *****/

/***** content-row *****/
.content-row-holder {
    position: relative;
    display: table;
    width: 100%;

    .content-row {
        display: table-row;

        .content-row-column {
            display: table-cell;
            position: relative;
            text-align: center;
            width: 50%;
            
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
/***** content-row *****/

/***** FORMS *****/

.form-icon {
    font-size: 74px;
}

.form-input {
    ion-input{
        border: solid 1px $secondary;
     }

    .show-password {
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        position: absolute;
        top: 50%;
        right: 10px;
        z-index: 99;
    }
}

/***** BUTTONS *****/
.btn {
    padding: 5px 20px;
    // border-radius: 10px;

    text-decoration: none;

    &.bigger {
        font-size: 20px;
    }
}

.btn-bold {
	font-weight: bold!important;
}
.btn-black-border {
	padding: 6px 12px!important;
	background-color: #FFF;
	border: 2px solid #000!important;
	border-radius: 0px!important;
	color: #000;
	text-decoration: none;
}
.btn-black, .btn-black *, .btn-black-border:hover {
	padding: 6px 12px!important;
	background-color: #000!important;
	border: 2px solid #000!important;
	border-radius: 0px!important;
	color: #FFF!important;
	text-decoration: none;
}

/***** BUTTONS *****/

@media only screen and (min-width: 768px) {
    .visible-sm {
        display: none!important;
    }
}

@media only screen and (max-width: 768px) {
    .schedule-tile-holder {
        display: block;
    }
    
    .img-row-holder {
        .img-row {
            .img-row-column {
                &.wid-5 {
                    width: 100%;
                }
                &.wid-4 {
                    width: 100%;
                }
                &.wid-3 {
                    width: 100%;
                }
                &.wid-2 {
                    width: 100%;
                }
                
                width: 100%;
            }
        }
    }

    .hidden-sm {
        display: none!important;
    }
    .visible-sm {
        display: inline-block;
    }
    .max-width-card-double {
        width: 100%;
        max-width: 750px;
    }

    h1, h2, h3, h4, h5, h6, div {
        &.bigger-font {
            font-size: 18px!important;
        }
        &.med-font {
            font-size: 22px!important;
        }
        // &.main-font {
        //     font-size: 30px!important;
        // }
        &.big-font {
            font-size: 40px!important;
        }
    }

    .full-page {
        background-size: auto 100%;
        background-position: center center;
    }

    .three-quart-page {
        background-size: auto 100%;
        background-position: center center;
    }

    .middle-sm {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    // .bottom-sm {
    //     position: absolute;
    //     bottom: 1em!important;
    //     left: 50%!important;
    //     transform: translate(-50%, -5px)!important;
    // }

    .waiting-logo {
        max-height: 300px;
        width: auto;
        max-width: 100%;
    }
    
    /***** chessboard *****/
    .chessboard {
        .chessboard-row {
            display: block;

            .chessboard-column {
                display: block;
            }
        }
    }
    /***** chessboard *****/
  }

  .photo-strip {
    display: table;

    .photo-strip-row {
        display: table-row;

        .photo-strip-item {
            display: table-cell;
    
            img {
                width: 100%;
                height: auto;
            }

            &.set-200 {
                width: 200px;
            }
    
            &.quart {
                width: 25%;
            }
        }
    }
}

  .flex-gallery {
    ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        padding: 0px;
        margin: 0px;
      }
      
      li {
        height: 40vh;
        flex-grow: 1;
      }

      li:last-child {
        flex-grow: 10;
      }
      
      img {
        max-height: 100%;
        min-width: 100%;
        object-fit: cover;
        vertical-align: bottom;
      }
}

// ion-datetime {
//     position: fixed;
//     bottom: auto;
//     top: 0px;
//     left: 0px;
//   }

ion-toolbar {
    background-color: $primary!important;
    --background: $primary!important;
    color: $secondary;

    ion-button {
        color: $secondary;
    }

    ion-icon {
        color: $secondary;
    }

    // ion-title {
    //     text-align: center;
    // }
}

.chip-menu {
    white-space: nowrap;
    overflow-x: auto;

    div.thumb {
        height: 75px;
        width: 75px;
        display: inline-block;

        img {
            max-height: 75px;
            max-width: 75px;
        }
    }
}
@media (max-aspect-ratio: 1/1) {
      .flex-gallery {
        li {
            height: 30vh;
          }
      }
    
  }
  @media (max-height: 480px) {
    .flex-gallery {
        li {
        height: 80vh;
        }
    }
  }

  @media (max-aspect-ratio: 1/1) and (max-width: 480px) {
    .flex-gallery {
        ul {
        flex-direction: row;
        }
    
        li {
        height: auto;
        width: 100%;
        }
    
        img {
        width: 100%;
        max-height: 75vh;
        min-width: 0;
        }
    }
  }

  @media only screen and (max-width: 768px) {
    .heading-main {
        font-size: 30px!important;
    }

    .product-display-holder {
        .product-display {
            display: block;
            width: 100%;
            padding: 10px;
        }
    }

    .photo-strip {
        display: block;
        .photo-strip-row {
            display: block;
            .photo-strip-item {
                display: block;
                &.quart {
                    width: 100%;
                }
            }
        }
    }

    .service-holder {
        .service {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    // .middle {
    //     position: relative;
    //     transform: none;
    //     top: unset;
    //     left: unset;
    // }

    .middle-vert {
        position: relative;
        transform: none;
    }
  }