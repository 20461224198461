@font-face {
    font-family: 'main-font';
    src: url('/assets/fonts/Gotham Book.otf');
}

@font-face {
    font-family: 'FontAwesome';
    src: url("/assets/fonts/fa-solid-900.woff2") format("woff2"), url("/assets/fonts/fa-solid-900.ttf") format("truetype"); 
}

.main-font {
    font-family: 'main-font';
    // text-transform: lowercase;
}

@font-face {
    font-family: 'main-font-bold';
    src: url('/assets/fonts/Timeless-Bold.ttf');
}

.main-font-bold {
    font-family: 'main-font-bold';
    // text-transform: lowercase;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat.woff');
}
@font-face {
    font-family: 'Montserrat-bold';
    src: url('/assets/fonts/Montserrat-Bold.woff');
}

.montserrat {
    font-family: 'Montserrat';
    // text-transform: lowercase;
}

@font-face { 
	font-family: LuloCleanOne; 
	src: url('/assets/fonts/LuloCleanOne.ttf'); 
	font-weight: normal; 
}

.lulo-clean, .lulo-clean * {
	font-family: "LuloCleanOne", Arial, sans-serif!important; 
}

@font-face { 
	font-family: LuloCleanOneBold; 
	src: url('/assets/fonts/LuloCleanOneBold.otf'); 
	font-weight: normal; 
}

.lulo-bold, .lulo-bold * {
	font-family: "LuloCleanOneBold", Arial, sans-serif!important; 
}

@font-face { 
	font-family: ThirdRail; 
	src: url('/assets/fonts/Third-Rail.ttf'); 
	font-weight: normal; 
}

.third-rail, .third-rail h1 {
	font-family: "ThirdRail", Arial, sans-serif!important;
	
}

@font-face { 
	font-family: GothamBook; 
	src: url('/assets/fonts/Gotham Book.otf'); 
	font-weight: normal; 
}

.gotham-book, .gotham-book h1 {
	font-family: "GothamBook", Arial, sans-serif;
	
}

.add-pointer {
    cursor: pointer;
}

h1 {
    &.main-font, &.main-font-bold {
        // font-size: 50px;
        // font-weight: bold;

        span {
            // border-bottom: 3px solid #000;
            padding: 0px 20px;
        }
    }

    &.sub-font {
        font-size: 35px;
        font-weight: bold;
    }
}

.pos-rel {
    position: relative;
}

.pos-btm {
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.btm-10 {
    bottom: 10px;
}

.pos-top {
    position: absolute;
    top: 0px;
    left: 0px;
}

.vert-top {
    vertical-align: top;
}

.wid-100 {
    width: 100%;
}

.banner {
    position: relative;
    min-height: 500px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    // background-attachment: fixed;

    &.banner-left {
        background-position-x: left;
    }
    &.banner-right {
        background-position-x: right;
    }
    &.banner-center {
        background-position-x: center;
    }

    transition: all 4s ease;

    .banner-video {
        z-index: 1;
        width: 100vw;
        height: auto;
    }

    .banner-overlay {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 100;
    }

    .banner-inner {
        display: table;
        // background-color: rgba($color: #000, $alpha: 0.5);

        min-height: 500px;
        height: 100%;
        width: 100%;

        .banner-inner-row {
            display: table-row;

            .banner-inner-cell {
                display: table-cell;
                vertical-align: middle;

                h1 {
                    font-size: 36px;
                    color: $primary;
                    text-shadow: 2px 2px 5px #000;

                    span {
                        padding: 3px 20px;
                        // border-bottom: 2px dashed $primary;
                    }
                }

                ion-button {
                    color: $primary;
                    --border-color: $primary;
                    // --background: #000;
                    background-color: rgba($color: #000000, $alpha: 0.8);
                }
            }
        }
    }

    &.btm-border {
        border-bottom: 20px solid #000;
    }
}


.products-block .swiper-slide img {
    max-height: none!important;
}
.tile {
    border-radius: 10px;

    &.tile-menu-item-image {
        width: 350px;
        height: 350px;

        img {
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            margin: auto;

            width: auto;
            height: auto;

            max-width: 100%;
            max-height: 350px;
        }
    }

    &.tile-image {
        ion-img {
            // min-height: 200px;
            min-width: 250px;
        }

        .tile-image-block {
            height: 150px;
            width: 100%;

            background-position: top center;
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
    }

    &.tile-schedule {
        border: 2px solid #000;
        background-color: #FFF;
        color: #000;

        &.selected {
            background-color: #000;
            color: #FFF;
        }
    }

    &.tile-menu-item {
        position: relative;
        width: 250px;
        margin: 0px auto;

        ion-img {
            // min-height: 200px;
            // min-width: 250px;
        }

        .tile-image-block {
            height: 250px;
            width: 100%;
            overflow: hidden;

            img {
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                margin: auto;

                width: auto;
                height: auto;

                // max-width: 250px;
                // max-height: 250px;
            }
            

            background-position: top center;
            background-repeat: no-repeat;
            background-size: 100% auto;
        }

        .tile-buttons {
            position: absolute;
            bottom: -50px;
            right: 0px;
        }
    }
 }

 .thumb-slides {
    --bullet-background: #FFF;
    --bullet-background-active: #FFF;
    height: 75px;

    ion-img {
        max-height: 75px;
        max-width: 75px;
    }
 }

.slides {
    --bullet-background: #FFF;
    --bullet-background-active: #FFF;

    &.slides-tile {
        ion-img {
            min-height: 250px;
            min-width: 250px;
        }
    }
}

.tile-responsive {
    position: relative;
    vertical-align: top;
    display: inline-block;
    width: 25%;
    max-width: 350px;

    &.tile-third {
        width: 33%;
        max-width: 400px;
    }
    &.tile-double {
        width: 50%;
        max-width: 700px;
    }

    &.tile-triple {
        width: 75%;
        max-width: 1050px;
    }
}

.calendar-display {
    border: 2px solid $secondary;

    .calendar-date {
        // background-color: $secondary;
        // color: #FFF;
        font-size: 15px;
    }

    .calendar-info {
        font-size: 14px;
    }
}

.hash-cloud {
    vertical-align: middle;
    
    img {
        max-height: 180px;
    }

    .tag-holder {
        min-height: 180px;
    }

    .tag-cloud {
        vertical-align: middle;
        display: inline-block;
        font-size: 11px;
        padding: 5px 15px;
        margin:2px;
        background-color: #000;
        color: #FFF;
        border-radius: 5px;
    }
}

@media only screen and (max-width: 768px) {
    .sm-inner-block {
        span {
            display: block;
            min-height: 150px;
        }
    }
    .banner {
        min-height: 250px;
        background-size: auto 100%;
    }

    .tile-responsive {
        width: 100%;

        &.tile-third {
            width: 100%;
        }

        &.tile-double {
            width: 100%;
        }
    
        &.tile-triple {
            width: 100%;
        }
    }

    .banner-inner {
        min-height: 300px!important;

        .banner-inner-row {
            .banner-inner-cell {
                vertical-align: bottom!important;
            }
        }
    }
  }