@import './variables.scss';

ion-tabs {
    ion-tab-bar {
        background-color: rgba($color: $secondary, $alpha: 1);
    
        ion-tab-button {
            background-color: rgba($color: $secondary, $alpha: 1);
            color: $primary;
            font-weight: bold;

            ion-badge {
                background-color: $tertiary;
                // color: $primary;
            }

            &:hover {
                color: $primary;

                ion-label, ion-icon {
                    font-weight: bold;
                }

                ion-badge {
                    background-color: $primary;
                    color: $tertiary;
                }
            }
            
            &.tab-selected {
                color: $primary;
                font-weight: bold;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

.slide-menu {
    ion-badge {
        background-color: $tertiary;
        // color: $primary;
    }
}

.menu-logo {
    height: 60px;
    margin: 5px 0px;
    text-align: left;
}

@import './nav.scss';